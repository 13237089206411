(function() {
  'use strict';

  angular
    .module('techApp', [
      'ngAnimate',
      'ngSanitize',
      'ngMessages',
      'ngAria',
      'ngResource',
      'ui.router',
      'ui.bootstrap',
      'toastr',
      'angularMoment'
    ]);

})();

(function() {
  'use strict';

  angular
    .module('techApp')
    .directive('navbar', navbar);

  /** @ngInject */
  function navbar() {
    var directive = {
      restrict: 'E',
      templateUrl: 'app/components/navbar/navbar.html',
      scope: {},
      controller: NavbarController,
      controllerAs: 'vm',
      bindToController: true
    };

    return directive;

    /** @ngInject */
    function NavbarController() {
      // "vm.creation" is avaible by directive option "bindToController: true"
    }
  }

})();

(function() {
    'use strict';

    angular
        .module('techApp')
        .directive('taLoader', taLoader);

    function taLoader() {
        var directive = {
            restrict:    'E',
            templateUrl: 'app/components/loaders/loader.html',
            link:        linkFunc
        };

        return directive;

        function linkFunc(scope, el) {
            scope.$on('loader_show', function() {
                return el.show();
            });
            return scope.$on('loader_hide', function() {
                return el.hide();
            });
        }
    }

})();

(function() {
  'use strict';

  angular
    .module('techApp')
    .factory('REST', REST);

  /* @ngInject */
  function REST($resource, RESTConfig) {
    return $resource(RESTConfig.api + '/:user/', {}, {
      // Query specific parameters
      getUser:  {
        method: 'GET'
      },
      postVote: {
        method: 'PATCH'
      }
    });
  }
})();

(function() {
  'use strict';

  angular
    .module('techApp')
    .config(config)
    .factory('httpInterceptor', httpInterceptor);

  /* @ngInject */
  function config($httpProvider) {
    $httpProvider.interceptors.push('httpInterceptor');
  }

  httpInterceptor.$inject = ['$q', '$rootScope', '$injector'];
  /* @ngInject */
  function httpInterceptor($q, $rootScope, $injector) {

    var _http = null;
    /** Public methods object */
    var service     = {};

    service.request       = request;
    service.response      = response;
    service.responseError = responseError;

    return service;

    function request(config) {
      // Show loader
      $rootScope.$broadcast('loader_show');

      return config || $q.when(config);
    }

    function response(responsed) {
      _http = _http || $injector.get('$http');

      if (_http.pendingRequests.length < 1) {
        // Hide loader
        $rootScope.$broadcast('loader_hide');
      }

      return responsed || $q.when(responsed);
    }

    function responseError(response) {
      _http = _http || $injector.get('$http');

      if (_http.pendingRequests.length < 1) {
        // Hide loader
        $rootScope.$broadcast('loader_hide');
      }

      return $q.reject(response);
    }
  }
})();

(function() {
    'use strict';

    angular
        .module('techApp')
        .config(router)
        .controller('MainController', MainController);

    /** @ngInject */
    function router($stateProvider) {
        $stateProvider
            .state('home', {
                url:          '/user/:hash',
                templateUrl:  'app/main/main.html',
                controller:   'MainController',
                controllerAs: 'vm'
            });
    }

    /** @ngInject */
    function MainController(toastr, REST, $stateParams, $state) {
        var vm        = this;
        vm.sendAnswer = sendAnswer;

        activate();

        function activate() {
            REST.getUser({user: $stateParams['hash']}).$promise
                .then(function(success) {
                    vm.user = success;
                })
                .catch(function() {
                    $state.go('error');
                });
        }

        /**
         * Click event
         *
         * @param answer
         */
        function sendAnswer(answer) {
            vm.user.data.vote = answer;
            sendResponse(vm.user);
        }

        /**
         * Send vote to the server and return response
         *
         * @param user
         */
        function sendResponse(user) {
            user.$postVote({user: user.data.hash})
                .then(function(success) {
                    vm.user = success;
                    toastr.success('Updated');
                })
                .catch(function() {
                    toastr.error('Loading failed!');
                });
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('techApp')
        .config(router)
        .controller('ErrorController', ErrorController);

    /** @ngInject */
    function router($stateProvider) {
        $stateProvider
            .state('error', {
                url:          '/error/404',
                templateUrl:  'app/error/404.html',
                controller:   'ErrorController',
                controllerAs: 'vm'
            });
    }

    /** @ngInject */
    function ErrorController() {

        activate();

        function activate() {
        }
    }
})();

(function() {
  'use strict';

  angular
    .module('techApp')
    .run(runBlock);

  /** @ngInject */
  function runBlock($log) {

    $log.debug('runBlock end');
  }

})();

(function() {
  'use strict';

  angular
    .module('techApp')
    .config(routerConfig);

  /** @ngInject */
  function routerConfig($urlRouterProvider) {
    $urlRouterProvider.otherwise('/');
  }

})();

(function() {
  'use strict';

  angular
    .module('techApp')
    .constant('RESTConfig', {
      //api: 'http://tech-experience.dataworkers.l/api'
      api: 'http://tech-experience.dataworkers.eu/api'
    });

})();

(function() {
  'use strict';

  angular
    .module('techApp')
    .config(config);

  /** @ngInject */
  function config($logProvider, toastrConfig) {
    //moment().tz("Europe/Paris").format();

    // Enable log
    $logProvider.debugEnabled(true);

    // Set options third-party lib
    toastrConfig.allowHtml = true;
    toastrConfig.timeOut = 3000;
    toastrConfig.positionClass = 'toast-top-right';
    toastrConfig.preventDuplicates = false;
    toastrConfig.progressBar = true;
  }

})();

angular.module("techApp").run(["$templateCache", function($templateCache) {$templateCache.put("app/error/404.html","<div class=\"row\"><div class=\"col-md-12\"><div class=\"error-template\"><h1>Oops!</h1><h2>Benutzer nicht gefunden</h2><div class=\"error-details\">Sorry, an error has occured, Requested page not found!</div><div class=\"error-actions\"><a href=\"mailto:gardlo@ftw.at\" class=\"btn btn-danger btn-lg\"><span class=\"glyphicon glyphicon-envelope\"></span> Kontaktiere Uns</a></div></div></div></div>");
$templateCache.put("app/main/main.html","<ta-loader></ta-loader><div ng-if=\"vm.user\"><div class=\"alert alert-info\" ng-if=\"vm.user.data.vote\">Sie haben bereits gewählt für: <b>{{vm.user.data.vote}}</b> (<span am-time-ago=\"vm.user.data.updated_at | amUtc\"></span>)</div><div class=\"jumbotron\"><h2>Hallo, {{ vm.user.data.name }}</h2><p class=\"lead\">Möchten Sie dem AIT - Technology Experience Test-user Pool beitreten und stimmen Sie der Übertragung Ihrer Stammdaten aus der FTW Datenbank zu?</p><p><div class=\"form\"><div class=\"form-group\"><textarea data-ng-model=\"vm.user.data.comment\" class=\"form-control\" rows=\"3\" placeholder=\"Anmerkungen\"></textarea></div></div><div class=\"row\"><div class=\"col-xs-6 text-right\"><button class=\"btn btn-lg btn-success\" ng-click=\"vm.sendAnswer(\'Ja, gerne!\')\">Ja, gerne!</button></div><div class=\"col-xs-6 text-left\"><button class=\"btn btn-lg btn-danger\" ng-click=\"vm.sendAnswer(\'Nein, danke!\')\">Nein, danke!</button></div></div></p></div><div class=\"row marketing\"><div class=\"col-lg-6\"><h4>AIT Disclaimer:</h4><p><span class=\"label label-danger\">Wichtig:</span> Das AIT verwendet Ihre Daten selbstverständlich nur für die Rekrutierung von Benutzerstudien. Es erfolgt keine anderweitige Verwendung Ihrer Daten und keine Weitergabe an Dritte.</p></div><div class=\"col-lg-6\"><h4>Informationen:</h4><p><span class=\"label label-info\">Info:</span> Sollten Sie Fragen haben wenden Sie sich bitte an <a href=\"mailto:sebastian.egger@ait.ac.at\">sebastian.egger@ait.ac.at</a></p></div></div></div>");
$templateCache.put("app/components/loaders/loader.html","<div class=\"alert alert-warning\">Loading...</div>");
$templateCache.put("app/components/navbar/navbar.html","<div class=\"header clearfix\"><nav class=\"hidden-xs\"><ul class=\"nav nav-pills pull-right\"><li role=\"presentation\" class=\"active\"><a href=\"http://www.ait.ac.at/departments/innovation-systems/business-units/technology-experience/\">ait.ac.at</a></li></ul></nav><h4 class=\"text-muted\">Tech-experience.at</h4></div>");}]);